<template>
  <body class="login-body">

    <div class="login-card">
      <div class="top-border"></div>
      <form class="login-content">
        <h1>登录</h1>
        <p>安装电箱公司端管理后台</p>
        <div class="username-container">
          <label>手机号</label>
          <div class="login-input">
            <InputText v-model="loginForm.mobile" placeholder="请输入手机号" />
          </div>
        </div>
        <div class="password-container">
          <label>密码</label>
          <div class="login-input">
            <InputText
              v-model="loginForm.password"
              type="password"
              placeholder="请输入密码"
            />
          </div>
        </div>
        <div class="forgetpassword-container">
          <span
            class="forget-password"
            @click="err('请在手机App上修改密码', '提示')"
            >忘记密码?</span
          >
        </div>
        <div>
          <Button
            :label="btnDisabled ? '正在登录' : '登录'"
            @click="login"
            :disabled="btnDisabled"
          />
        </div>
      </form>
      <AppFooter />
      <Toast />
    </div>
  </body>
</template>

<script>
import axios from "axios";
import AppFooter from "../AppFooter.vue";
export default {
  components: {
    AppFooter: AppFooter,
  },
  data() {
    return {
      loginForm: {
        mobile: "",
        password: "",
      },
      btnDisabled: false,
    };
  },
  methods: {
    err(msg, title, life) {
      this.btnDisabled = false;
      this.$toast.add({
        severity: "error",
        summary: title || "登录错误",
        detail: msg,
        life: life || 3000,
      });
    },
    waiting() {
      this.btnDisabled = true;
    },
    login() {
      const { mobile, password } = this.loginForm;
      if (!mobile) return this.err("请输入手机号");
      else if (!/^1[345789]\d{9}$/.test(mobile))
        return this.err("手机号格式不正确");
      if (!password) return this.err("请输入密码");
      this.waiting();
      axios
        .post(
          "/houseKeeperUserInfo/checkLogin",
          `mobile=${mobile}&password=${password}`
        )
        .then(({ data }) => {
          if (data.code === 0) {
            localStorage.setItem("token", JSON.stringify(data));
            this.$router.push({ path: "/" });
          } else this.err(data.msg, "登录失败", 5000);
        })
        .catch((err) => {
          console.error(err);
          this.err("系统错误，请稍候重试");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-body {
  background: url("/assets/layout/images/bg-login.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
  display: flex;
  flex-direction: row;
  height: 100vh;
  padding: 0;

  .login-panel {
    position: fixed;
    text-align: center;
    height: 100%;
    width: 90px;
    opacity: 0.9;
    background: radial-gradient(circle at 50% 3%, #f3f4f9, #d7dbe8);
  }

  .login-card {
    align-self: center;
    margin-left: calc(10% + 90px);
    width: 25%;
    min-width: 420px;
    opacity: 0.9;
    border-radius: 2px;
    background: radial-gradient(circle at 50% 3%, #f3f4f9, #d7dbe8);

    .top-border {
      height: 20px;
      opacity: 1;
    }

    .login-content {
      padding: 20px 50px;

      h1 {
        color: #1b1c1e;
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 30px;
      }

      p {
        color: #313842;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 40px;
      }

      label {
        font-size: 16px;
        color: #313842;
        margin-bottom: 15px;
      }

      .login-input {
        margin-top: 15px;
        margin-bottom: 15px;

        input {
          width: 100%;
        }
      }

      .forgetpassword-container {
        text-align: right;
        margin-bottom: 40px;
        color: #3ca4e7;
      }

      button {
        width: 100%;
        margin-bottom: 60px;
      }
    }
  }
}

@media screen and (max-width: 40em) {
  .login-body {
    background-position: right;
    flex-direction: column;

    .top-border {
      display: none;
    }

    .login-panel {
      display: none;
    }

    .login-card {
      width: 100%;
      min-width: 100%;
      margin-left: 0;
      height: 100%;

      .login-content {
        padding: 30px 30px;
      }
    }
  }
}
</style>